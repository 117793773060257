// src/theme.ts

import { createTheme } from "@mui/material/styles";

// Define a paleta de cores personalizadas para a aplicação
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-Bold.eot');
    src: local('Mabry Pro Bold'), local('MabryPro-Bold'),
        url('/font/MabryPro/MabryPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-Bold.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-Bold.woff') format('woff'),
        url('/font/MabryPro/MabryPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-Medium.eot');
    src: local('Mabry Pro Medium'), local('MabryPro-Medium'),
        url('/font/MabryPro/MabryPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-Medium.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-Medium.woff') format('woff'),
        url('/font/MabryPro/MabryPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-Black.eot');
    src: local('Mabry Pro Black'), local('MabryPro-Black'),
        url('/font/MabryPro/MabryPro-Black.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-Black.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-Black.woff') format('woff'),
        url('/font/MabryPro/MabryPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-LightItalic.eot');
    src: local('Mabry Pro Light Italic'), local('MabryPro-LightItalic'),
        url('/font/MabryPro/MabryPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-LightItalic.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-LightItalic.woff') format('woff'),
        url('/font/MabryPro/MabryPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-BoldItalic.eot');
    src: local('Mabry Pro Bold Italic'), local('MabryPro-BoldItalic'),
        url('/font/MabryPro/MabryPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-BoldItalic.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-BoldItalic.woff') format('woff'),
        url('/font/MabryPro/MabryPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-Light.eot');
    src: local('Mabry Pro Light'), local('MabryPro-Light'),
        url('/font/MabryPro/MabryPro-Light.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-Light.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-Light.woff') format('woff'),
        url('/font/MabryPro/MabryPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-BlackItalic.eot');
    src: local('Mabry Pro Black Italic'), local('MabryPro-BlackItalic'),
        url('/font/MabryPro/MabryPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-BlackItalic.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-BlackItalic.woff') format('woff'),
        url('/font/MabryPro/MabryPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-MediumItalic.eot');
    src: local('Mabry Pro Medium Italic'), local('MabryPro-MediumItalic'),
        url('/font/MabryPro/MabryPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-MediumItalic.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-MediumItalic.woff') format('woff'),
        url('/font/MabryPro/MabryPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-Italic.eot');
    src: local('Mabry Pro Italic'), local('MabryPro-Italic'),
        url('/font/MabryPro/MabryPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-Italic.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-Italic.woff') format('woff'),
        url('/font/MabryPro/MabryPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-Regular.eot');
    src: local('Mabry Pro'), local('MabryPro-Regular'),
        url('/font/MabryPro/MabryPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-Regular.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-Regular.woff') format('woff'),
        url('/font/MabryPro/MabryPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


      `,
    },
  },
  palette: {
    mode: "dark", // Tema escuro
    primary: {
      main: "#29469E", // Azul do logo
      light: "#4C75D6", // Azul mais claro para hover e botões
      dark: "#1D3571", // Azul mais escuro para contrastes
    },
    secondary: {
      main: "#FF4081", // Cor complementar em tom vibrante (rosa choque)
      light: "#FF80AB", // Cor mais clara para hover
      dark: "#C60055", // Cor mais escura para contrastes
    },
    background: {
      default: "#121212", // Fundo escuro para o tema
      paper: "#1F1F1F", // Fundo mais claro para os elementos de conteúdo (como o post)
    },
    text: {
      primary: "#E0E0E0", // Texto claro para o tema escuro
      secondary: "#B0B0B0", // Texto secundário (menos destacado)
    },
  },
  typography: {
    fontFamily: "Mabry Pro, sans-serif",
    h1: {
      fontWeight: 700,
      fontSize: "2.25rem",
      lineHeight: 1.3,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontWeight: 700,
      fontSize: "1.75rem",
      lineHeight: 1.35,
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontWeight: 700,
      fontSize: "1.5rem",
      lineHeight: 1.4,
      letterSpacing: "0em",
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.25rem",
      lineHeight: 1.4,
      letterSpacing: "0.00735em",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.125rem",
      lineHeight: 1.5,
      letterSpacing: "0em",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.6,
      letterSpacing: "0.00938em",
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.855rem",
      lineHeight: 1.43,
      letterSpacing: "0.01071em",
    },
    button: {
      fontWeight: 500,
      fontSize: "0.8125rem",
      letterSpacing: "0.05em",
      textTransform: "capitalize",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.6875rem",
      lineHeight: 1.4,
      letterSpacing: "0.03333em",
    },
    overline: {
      fontWeight: 500,
      fontSize: "0.6875rem",
      letterSpacing: "0.1em",
      textTransform: "uppercase",
    },
  },
  spacing: 8, // Define o espaçamento padrão (8px)
});

export default theme;
