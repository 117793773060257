import React, { PropsWithChildren } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme"; // Caminho para o arquivo de tema
import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  Container,
  CssBaseline,
  Divider,
  Toolbar,
  Typography,
} from "@mui/material"; // Para aplicar os estilos base (reset CSS)
import { StaticImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

export default function Layout({ children }: PropsWithChildren) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logos/logo.png" }) {
        publicURL
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline garante que os estilos básicos sejam aplicados corretamente */}
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href="/src/images/logos/favicon.ico" />
        <link rel="apple-touch-icon" href="/src/images/logos/apple-icon.png" />

        <meta
          property="og:title"
          content="Blog | Corpilot - AI-Powered Insights for Smarter Business Decisions"
        />
        <meta
          property="og:description"
          content="Explore the Corpilot blog for insights on how AI and data analytics are transforming industries like sales, marketing, and finance. Stay informed with expert tips, trends, and strategies to make smarter, data-driven decisions."
        />
        <meta property="og:image" content={data.file.publicURL} />
        <meta property="og:url" content={`https://blog.corpilot.com/`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Blog | Corpilot - AI-Powered Insights for Smarter Business Decisions"
        />
        <meta
          name="twitter:description"
          content="Explore the Corpilot blog for insights on how AI and data analytics are transforming industries like sales, marketing, and finance. Stay informed with expert tips, trends, and strategies to make smarter, data-driven decisions."
        />
        <meta name="twitter:image" content={data.file.publicURL} />

        <meta
          name="description"
          content="Explore the Corpilot blog for insights on how AI and data analytics are transforming industries like sales, marketing, and finance. Stay informed with expert tips, trends, and strategies to make smarter, data-driven decisions."
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://blog.corpilot.com/" />
      </Helmet>
      <CssBaseline />
      <main>
        <AppBar position="static" color="transparent" variant="outlined">
          <Container maxWidth="lg">
            <Toolbar>
              <ButtonBase component={Link} to="/" disableRipple>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={2}
                >
                  <StaticImage
                    src="./images/logos/white.svg"
                    alt="Logo"
                    placeholder="blurred"
                    layout="fixed"
                    height={40}
                  />
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Typography
                    variant="h3"
                    fontWeight={100}
                    color="textSecondary"
                    component="div"
                  >
                    Blog
                  </Typography>
                </Box>
              </ButtonBase>
              <Box sx={{ flexGrow: 1 }} />
              {/* CTA Button */}
              <Button
                variant="text"
                color="inherit"
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                }}
                href="https://corpilot.com/"
              >
                See It in Action
              </Button>
            </Toolbar>
          </Container>
        </AppBar>

        {/* Content */}
        <Box
          sx={{
            padding: 4,
            minHeight: "calc(100vh - 64px)", // Compensar a altura da AppBar
          }}
        >
          {children}
        </Box>
      </main>
    </ThemeProvider>
  );
}
